import logo from './flexlogo.svg';
import './App.css';
import axios from 'axios';
import ReactLoading from "react-loading";
import { JSEncrypt } from 'jsencrypt'
import React, { useState } from 'react';


const API = "https://flexxsign-api.azure-api.net/FlexxSign-Functions/";
const publicKeyPem = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA25bfJSCpZpBMH+rZxD4Q
cdtfARvUKPUVYxjyl8n9CZOIvOYVHQ7lf92s+9jl9D24xwJRkq1ZYl98/jEyI/7P
NJbOUhOVJtJidqym+Nb1oImfyOnBA6UqQtPB6VE2+KDrlKDT+cKBund+JqqhtK2l
pz+PE+cYzHgJtjRno8sCmYFtv0pz8PiKBEBhVemKOQZmyZ2IvF936eaN5I9x/ysX
zd4zBJB/Fy9kNEDCxQlUDIld10Azj38N19xMOQTlM2JJ4uqxlRmSoqiRDZK96XPc
uoBb1Zub4+tfPytrsflcKWSwvVkBjRYSGChe0f4dDmwLelOoycW7PHYWGfKtvxms
TwIDAQAB
-----END PUBLIC KEY-----`;

// Initialize var data2
//var data2 = null;
//var idioma = "en";
var userverified = false;
var firstchargedone = false;
var pinsent = false;

function encrypt(plaintext, publicKey) {
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(publicKey);
  const encrypted = encryptor.encrypt(plaintext);
  return encrypted;
}

async function handleSubmit(event) {
  event.preventDefault();

}

// Send a get request including the token in the header
var data = {
  mail: ""
};

var data2 = {
  mail: "",
  method: ""
};

var data3 = {
  mail: "",
  pin: ""
};

var tiempo = 29;
var password = "";

function App() {

 
  // Control del loader
  const [loading, setloading] = useState(false);

  // Método para el loader (paso 1: recopilar información)
  function isloading() {
    setloading(true);
    // Display some random text while the information is being loaded
    setButtonText("Loading...");
    setTimeout(() => {
      // Display another random text while the information is being loaded
      setButtonText("Updating information...");
    }, 10000);
    setTimeout(() => {
      setButtonText("Getting data...");
    }, 20000);

    if (firstchargedone === true) {
      setTimeout(() => {
        // Aquí irá el proceso que define si la carga ha finalizado o no (se puede hacer con un fetch o con un axios.get)
        setloading(false);
      }, 34000); // Este tiempo se debe cambiar con el tiempo que tarde en cargar la información
    }
  }

  // Control del tiempo anti spam del botón
  const [delay, setDelay] = useState(false);

  function delayantispam() {
    setDelay(true);
    setButtonText("Verifying PIN...");
    setTimeout(() => {
      setDelay(false);
    }, 3000);
  }

  // Control de los elementos del formulario
  

  const [pokeid, setButtonText] = useState('');
  const [textorangebutton, settextorangebutton] = useState("Forgot Password");
  const [inputdisabled, setinputdisabled] = useState(false);
  const [selects, setSelects] = useState("pJefe"); // Estado para el select (DEFAULT: pJefe)
  const [butondiabled, setbutondiabled] = useState(false);

  const [telephonemethod, settelephonemethod] = useState(false); // Estado para el select 
  const [emailmethod, setemailmethod] = useState(false); // Estado para el select




  // pone invisible el segundo formulario
  const [primerForm, setFormVisible] = useState('text'); // pone visible el segundo formulario
  const [message, setMessage] = useState('');
  const [pinmessage, setPinMessage] = useState('Mail');
  const handleChange = event => {
    setMessage(event.target.value);


  };

  function intervalbutton() {
    // Your code here
    // Parameters are purely optional.
    setButtonText("Your new temporal password is: " + password + " you have " + tiempo + "s to copy it");
    tiempo--;
  }
  async function changeVis(type) {

    var segundoForm = document.getElementById('segundoForm');
    var captcha = document.getElementById('captcha');
    segundoForm.style.visibility = type;
    captcha.style.visibility = 'hidden';
  }


  /*function changeLenguage(lenguage) {
    idioma = lenguage;

    switch(idioma) {
      case "en": settextorangebutton("Forgot Passowrd"); break;
      case "es": settextorangebutton("Contraseña olvidada"); break;
      default: settextorangebutton("Forgot Passowrd"); break;
    }
    
  } */
  const config = {
    headers: {
      'Access-Control-Allow-Origin': '*',
      //'Ocp-Apim-Subscription-Key': process.env.SUBSCRIPTION_KEY.toString() // No está definido, pendiente de revisar
      'Ocp-Apim-Subscription-Key': "051f4a8e12f346599b9ab7d170b61cf9"
      
    }
  };


  function checkStatusAndSetButton(retryCount = 0, fdata, fconfig) {
    axios.post(API+"getstatus", fdata, config)
      .then((res) => {
        // Si el status es completed, se muestra la contraseña y se inicia el temporizador
        console.log(fdata)
        if (res.data.toString() === "completed") {
          document.getElementById('botonCopy').style.visibility = 'visible';
          setButtonText("Your new temporal password is: " + password + " you have " + 30 + "s to use it");
  
          var refreshIntervalId = setInterval(intervalbutton, 1000);
  
          setTimeout(() => {
            setButtonText("");
            document.getElementById('botonCopy').style.visibility = 'hidden';
            clearInterval(refreshIntervalId);
            // Al final del tiempo se hace un reset de la página
            window.location.reload();
          }, 30000);
        } else if (retryCount < 3) { // Si el status NO es completed y no hemos llegado al límite de intentos, reintenta
          setTimeout(() => {
            checkStatusAndSetButton(retryCount + 1, fdata, config); // Reintenta incrementando el contador de reintentos
          }, 50000); // Espera 50 segundos antes de reintentar
        } else {
          // Si hemos alcanzado el límite de reintentos sin éxito, muestra un mensaje de error
          setButtonText("There was an error resetting your password after several attempts, please try again later or contact your service desk");
        }
      })
      .catch((error) => {
        setButtonText("There was an internal error during the petition, please try again later");
      });
  }

  async function SendRequest(event) {
    //const token = process.env['SNOW_INSTANCE_URL']; // Azure API Management subscription key
    event.preventDefault();
    
    //axios.get('https://testpasswordfunctions.azurewebsites.net/api/HttpTrigger2?clientId=apim-testpasswordAPI'),

    data2 = {
      mail: message,
      method: selects
    }
    if (!pinsent) {
      data = {
        mail: message
      };
    }
    else {
      //pin = {
      //  pin: message
      //};
      data3 = {
        mail: data.mail,
        pin: message
      }
    }



    // Depending if the user is verified or not, the request when pressing the button will be different
    if (pinsent === true) {
      // Tercera vez que pulsas el botón
      delayantispam();

      // Enviamos petición para que se compare el PIN introducido con el que se ha enviado (se envía sólo el mail a la petición)
      axios.post(API + "getpin", data3, config) // Esta función nos debe decir si el pin es correcto o no AQUÍ HAY QUE PASARLE PIN Y NO DATA
        .then((res) => {
          

          if (res.data.toString() === "Correct PIN") {
            // Si el PIN es correcto, se genera la nueva contraseña random y se envía al usuario, faltaría mirar la hora

            // Bloqueamos el botón para que no se pueda pulsar
            setbutondiabled(true);

            // Generamos la contraseña random
            var chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            var passwordLength = 12;


            for (var i = 0; i <= passwordLength; i++) {
              var randomNumber = Math.floor(Math.random() * chars.length);
              password += chars.substring(randomNumber, randomNumber + 1);
            }
            let encryptedPassword = encrypt(password, publicKeyPem)
            // Aquí se debe llamar a la función de Ansible que cambia la contraseña en el AD
            var finaldata = {
              mail: data3.mail,
              generatedpassword: encryptedPassword
            }

            axios.post(API + "executereset", finaldata, config)
              .then((res) => {

              }, (error) => {

              });


            isloading();
            checkStatusAndSetButton(0,finaldata,config);
          }
          // Si el status es 403, el PIN es incorrecto y no se puede cambiar la contraseña hasta dentro de 24 horas
          else if (res.data.toString() === "Incorrect PIN") {
            setTimeout(() => {
              setButtonText("The PIN is not correct, please try again");
            }, 2500);

          }
          else {
            setTimeout(() => {
              setButtonText("You introduced the wrong PIN 3 times, you can't change your password until 24 hours have passed");
            }, 2500);
          }



        }, (error) => {
          setTimeout(() => {
            setButtonText("You introduced the wrong PIN 3 times, you can't change your password until 24 hours have passed");
          }, 2500);
        });

    }
    else if (userverified === true) {
      // Segunda vez que pulsas el botón
      axios.put(API + "generatepin", data2, config) //En esta función se genera el pin y se envía al usuario por el método seleccionado
        .then((res) => {

          setPinMessage('Pin');
          changeVis('hidden');

          setFormVisible('visible');
          setinputdisabled(false);

          setButtonText("A pin has been sent, please introduce it in the box to reset your password");
          setMessage("");
          pinsent = true;
          // Ahora debe aparecer un cuadro de texto donde introducir el pin
          userverified = false;
          settextorangebutton("Reset Password");
          // Desactivamos el userverified por posibles problemas

        }, (error) => {
          //setButtonText('An error has occurred, please refresh the page and try again');
          setPinMessage('Pin');
          changeVis('hidden');

          setFormVisible('visible');
          setButtonText("A pin has been sent, please introduce it in the box to reset your password");
          setMessage("");
          setinputdisabled(false);
          pinsent = true;
          // Ahora debe aparecer un cuadro de texto donde introducir el pin
          userverified = false;
          settextorangebutton("Reset Password");
        });

    }

    else {
      // Primera vez que pulsas el botón
      // If mail does not contain @ or .something, the API returns an error
      if (data.mail.indexOf("@") === -1 || data.mail.indexOf(".") === -1) {
        setButtonText('Please, introduce a valid email');
      }
      else {
        isloading();
        axios.put(API + "checkuser", data, config)
          .then((res) => {
           
            try {
              if (res.data === "Yes" || res.data ==="yes") {
                // Wait until the loading is finished
                setTimeout(() => {
                  // Ask the api for the reset possible options
                  axios.post(API + "getmethods" , data, config)
                    .then((res) => {
                      
                      if (res.data === "pTel method") {
                        setemailmethod(true);
                        setSelects("pSMS")
                      }
                      else if (res.data === "pJefe method") {
                        settelephonemethod(true);
                      }
                    }, (error) => {

                    });
                  setButtonText("Please, select the reset method");
                  // Verify user changing the boolean
                  userverified = true;
                  firstchargedone = true;
                  setinputdisabled(true);
                  settextorangebutton("Send");
                  setloading(false);
                  changeVis('visible');
                }, 28000);

              }
              // The user exists but it's not allowed to reset the password
              else {
                setTimeout(() => {
                  setButtonText("The response from the server is unexpected, please try again in a few minutes");
                }, 28000);
              }
            }
            // The user does not exist but for security reasons we don't want to show it
            catch (error) {
              setTimeout(() => {
                setButtonText("An error with the server response format has occurred, please try again in a few minutes");
              }, 28000);
            }

          }, (error) => {
            setTimeout(() => {
              setButtonText('An error has occurred, please try again in a few minutes');
            }, 28000); // Debe pasar un tiempo hasta que se hace el display del setbuttonText

          });
      }
    }
  }

  // Determinamos si la pag está cargada o no (isLoading or ispinloading)
  if (loading === true || delay === true) {

    return (
      <div className="App container" >

        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />

          <form class="login" onSubmit={handleSubmit} method="post">

            <div class="login__field ">
              <i class="login__icon fas fa-user"></i>
              <div>
                <input type={primerForm} name="email" class="login__input" onChange={handleChange} value={message} placeholder={pinmessage} autoComplete="off" input disabled={inputdisabled} />
              </div>

              <div>
                <select id="segundoForm" class="login__selector" name="typepins" value={selects} onChange={e => setSelects(e.target.value)}>
                  <option value="pJefe">send PIN to recovery email</option>
                  <option value="pSMS">send PIN by SMS</option>
                </select>
              </div>
              <ReactLoading class="loader" type={"bars"} color={"#ffffff"} height={70} width={37} />
            </div>
          </form>


          <span
            className="App-link"
          >
            {pokeid}
          </span>
          <button class="button login__submit" id="botonCopy" hidden onClick={() => {navigator.clipboard.writeText(this.state.password)}} >
            <span class="button__text" name="submit_btn" > copy </span>
            <i class="button__icon fas fa-chevron-right"></i>
          </button>
          
          
        </header>
      </div>

    )
  }
  else {

    return (

      <div className="App container" >

        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />

          <form class="login" onSubmit={handleSubmit} method="post">

            <div class="login__field">
              <i class="login__icon fas fa-user"></i>
              <div>
                <input type={primerForm} name="email" class="login__input" onChange={handleChange} value={message} placeholder={pinmessage} autoComplete="off" input disabled={inputdisabled} />
              </div>

              <div>
                <select id="segundoForm" class="login__selector" name="typepins" value={selects} onChange={e => setSelects(e.target.value)}>
                  <option value="pJefe" disabled={emailmethod} >send PIN to recovery email</option>
                  <option value="pSMS" disabled={telephonemethod}>send PIN by SMS</option>
                </select>
              </div>
              <div>
                <button class="button login__submit" onClick={SendRequest} disabled={butondiabled}>
                  <span class="button__text" name="submit_btn" > {textorangebutton} </span>
                  <i class="button__icon fas fa-chevron-right"></i>
                </button>
              </div>
            </div>

            
          </form>


          <span
            className="App-link"

          >
            {pokeid}
          </span>
          <button class="login__submit_2" id="botonCopy"  onClick={() => {navigator.clipboard.writeText(password)}} >
            <span class="button__text" name="submit_btn" > Copy to clipboard </span>
            <i class="button__icon fas fa-chevron-right"></i>
          </button>

          </header>


            


          <script src="https://www.google.com/recaptcha/api.js" async defer />

      </div>
    );
  }
}


export default App;



